import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  GeoAltFill,
  EnvelopeFill,
} from "react-bootstrap-icons";

export default function Footer() {
  return (
    <footer className='alt-footer-colour footer-end'>
      
      <Container fluid>
        <div className='footer-colour'>
        <Row className="footer-spacing align-items-center">
          <Col>
            <img
              src={require("../Images/white_logo.png")}
              width="120"
              height="60"
              className="SynBic-logo"
              alt="SynBic logo"
            />
          </Col>
        </Row>
        </div>

        <Row className='alt-footer-colour alt-footer-spacing'>
          <Col xs={12} md={4} className="mb-5">
            <h5 className='footer-heading'>Discover SynBic</h5>
            <div>
              <a href="/" className="remove-underline col-link footer-links">
                Home
              </a>
            </div>

            <div>
              <a href="/About" className="remove-underline col-link footer-links">
                About
              </a>
            </div>
          </Col>

          <Col xs={12} md={4} className="mb-5">
            <h5 className='footer-heading'>Our Events:</h5>
            <div>
              <a href="/Hackathons" className="remove-underline col-link footer-links">
                Hackathons
              </a>
            </div>

            <div>
              <a href="/Forums" className="remove-underline col-link footer-links">
                Forums
              </a>
            </div>
          </Col>

          <Col xs={12} md={4} className="mb-5">
            <h5 className='footer-heading'>Contact Us:</h5>
            <div>
              <a
                href="https://goo.gl/maps/B2ghuTzbgVswaC1v5"
                className="remove-underline footer-links"
              >
                <GeoAltFill className="bi bi-geo-alt-fill footer-button child-contact" />
                <p className="child-contact wrap-text">
                  Exhibition Rd, South Kensington, SW7 2BX
                </p>
              </a>
            </div>

            <div className="parent-contact">
              <a href="mailto:synbic@ic.ac.uk" className="remove-underline footer-links">
                <EnvelopeFill className="bi bi-envelope-fill footer-button child-contact" />
                <p className="child-contact">Synbic@ic.ac.uk</p>
              </a>
            </div>
          </Col>
        </Row>

        <Row>
        <div>
          <h5 className='footer-heading'>Follow Us:</h5>
          <a href="https://www.instagram.com/synbic/">
            <Button variant="outline-light" size="sm" className="social-button">
              <Instagram />
            </Button>
          </a>

          <a href="https://www.linkedin.com/company/synbic/about/">
              <Button
                variant="outline-light"
                size="sm"
                className="social-button"
              >
                <Linkedin />
              </Button>
            </a>

            <a href="https://twitter.com/synbic">
              <Button
                variant="outline-light"
                size="sm"
                className="social-button"
              >
                <Twitter />
              </Button>
            </a>

            <a href="https://www.facebook.com/SynBIC/">
              <Button
                variant="outline-light"
                size="sm"
                className="social-button"
              >
                <Facebook />
              </Button>
            </a>
            </div>
        </Row>
      </Container>
    </footer>
  );
}
