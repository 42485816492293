import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Book,
  FileSlides,
  Globe,
  Lightbulb,
  Megaphone,
  Printer,
} from "react-bootstrap-icons";

import Aos from "aos";
import "aos/dist/aos.css";

export default function WhyAttend() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <h2>🌐 Why Attend? 🌐 </h2>
      <Container>
        <Row className="mt-5">
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="1500">
              <FileSlides size={100}></FileSlides>
              <h4 className="attend-spacing">Showcase Your Research:</h4>
              <p className="attend-spacing">
                Share your passion and findings with peers who are just as
                enthusiastic about science as you are. It's your chance to shine
                on a prestigious stage.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="1000">
              <Globe size={100} />
              <h4 className="attend-spacing">Network and Connect:</h4>
              <p className="attend-spacing">
                Connect with like-minded individuals, mentors, and experts in
                the field, paving the way for potential collaborations and
                future opportunities.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="500">
              <Book size={100} />
              <h4 className="attend-spacing">Expand Your Knowledge:</h4>
              <p className="attend-spacing">
                Engage with diverse research topics within synthetic biology,
                bioengineering, and STEM. Gain insights into the latest
                advancements and breakthroughs.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="1500">
              <Megaphone size={100} />
              <h4 className="attend-spacing">Inspiring Speakers:</h4>
              <p className="attend-spacing">
                Listen to accomplished guest speakers who have left a
                significant mark on the industry, igniting your curiosity and
                motivation.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="1000">
              <Lightbulb size={100} />
              <h4 className="attend-spacing">Fostering Innovation:</h4>
              <p className="attend-spacing">
                Immerse yourself in a creative environment that fosters
                innovation, critical thinking, and problem-solving.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="500">
              <Printer size={100} />
              <h4 className="attend-spacing">Publication Opportunity:</h4>
              <p className="attend-spacing">
                Selected abstracts will have the chance to be published in our
                conference proceedings, amplifying your research's reach.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
