import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Aos from "aos";
import Button from "react-bootstrap/Button";
import "aos/dist/aos.css";

export default function IGEM() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div data-aos="fade-up" data-aos-delay="500">
      <h2>
        iGEM: The International Genetically Engineered Machine Competition{" "}
      </h2>
      <Container>
        <Row className="mt-5 justify-content-center">
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="700">
              <img
                src={require("../Images/iGEM-logo.png")}
                width="300"
                height="205"
                className="iGEM-logo"
                alt="iGEM logo"
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="750">
              The iGEM competition is one of the most renowned and prestigious
              platforms for undergraduate and high school students to engage in
              synthetic biology research. Founded in 2004, iGEM has grown
              exponentially, drawing teams from universities and schools
              worldwide.
            </div>
          </Col>
        </Row>

        <div className="mt-5">
          <Button
            variant="outline-dark"
            className="social-button"
            href="https://forms.office.com/e/LDkTKDBAvr"
          >
            {" "}
            Sign Up Now{" "}
          </Button>
        </div>
      </Container>
    </div>
  );
}
