import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Aos from "aos";
import Button from "react-bootstrap/Button";
import "aos/dist/aos.css";

export default function BIOMOD() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div data-aos="fade-up" data-aos-delay="500">
      <h2>Biomod: The Biological Machine Design Competition </h2>
      <Container>
        <Row className="mt-5 justify-content-center">
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="700">
              <img
                src={require("../Images/BIOMOD.jpeg")}
                width="350"
                height="80"
                className="iGEM-logo"
                alt="iGEM logo"
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div data-aos="fade-right" data-aos-delay="750">
              Biomod, short for Biological Machine Design, is another exciting
              synthetic biology competition that focuses on the design and
              construction of biomolecular systems with an emphasis on
              structural and functional aspects. While it is similar in spirit
              to iGEM, Biomod places a particular emphasis on nanoscale and
              microscale systems.
            </div>
          </Col>
        </Row>

        <div className="mt-5">
          <Button
            variant="outline-dark"
            className="social-button"
            href="https://forms.office.com/e/LDkTKDBAvr"
          >
            {" "}
            Sign Up Now{" "}
          </Button>
        </div>
      </Container>
    </div>
  );
}
