import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Hackathons from "./Pages/Hackathons";
import Conferences from "./Pages/Conferences";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Competitions from "./Pages/Competitions";

function App() {
  return (
    <div className="fonts">
      <NavBar />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Hackathons" element={<Hackathons />}></Route>
          <Route path="/Conferences" element={<Conferences />}></Route>
          <Route path="/Competitions" element={<Competitions />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
