import Carousel from "react-bootstrap/Carousel";

export default function OurTeam() {
  return (
    <Carousel >
      <Carousel.Item>
        <img
          src={require("../Images/Dawood.jpg")}
          width="309"
          height="512"
          alt="im1"
          className='home-img'
        />
        <Carousel.Caption className = 'carousel-caption'>
          <h4 className='carousel-text'>Dawood Khan</h4>
          <p className='carousel-text'> President</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Srinivas.jpg")}
          width="309"
          height="512"
          alt="im2"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Srinivas Balagopal</h4>
          <p> Vice President</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Ryan.jpg")}
          width="309"
          height="512"
          alt="im3"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Ryan Ang</h4>
          <p> Secretary</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Annika.jpg")}
          width="309"
          height="512"
          alt="im4"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Annika Keshu</h4>
          <p> Treasurer</p>
        </Carousel.Caption>
      </Carousel.Item>

    

      <Carousel.Item>
        <img
          src={require("../Images/WaiTheng.jpg")}
          width="309"
          height="512"
          alt="im6"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Wai Theng Mak</h4>
          <p> Promotion Officer</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Lewis.jpg")}
          width="309"
          height="512"
          alt="im7"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Lewis Goater</h4>
          <p> Professional Development Officer</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Athena.JPG")}
          width="309"
          height="512"
          alt="im8"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Athena Schumacher</h4>
          <p> Events Officer</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Chen.JPG")}
          width="309"
          height="512"
          alt="im9"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Chen Fan</h4>
          <p> Events Officer</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          src={require("../Images/Yuktha.JPG")}
          width="309"
          height="512"
          alt="im10"
          className='home-img'
        />
        <Carousel.Caption>
          <h4>Yuktha Ravinthiran</h4>
          <p> Promotion Officer</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
