import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Prescription2, Tree } from "react-bootstrap-icons";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChallengeAreas() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="events-intro-spacing">
      <div data-aos="fade-up" data-aos-delay="500">
        <h2>Potential Challenge Areas</h2>
      </div>

      <div>
        <div data-aos="fade-up" data-aos-delay="500">
          <Row className="mt-5 hackathons-challenges">
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className="mt-5"
            >
              <Prescription2 size={100} />
            </Col>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className="mt-5"
            >
              <h5>Healthcare Solutions</h5>
              <p>
                Develop novel medical devices, diagnostics, or therapies to
                address unmet healthcare needs, from improving patient care to
                enhancing disease detection and treatment.{" "}
              </p>
            </Col>
          </Row>
        </div>

        <div data-aos="fade-up" data-aos-delay="750">
          <Row className="hackathons-challenges">
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className="mt-5"
            >
              <h5>Environmental Sustainability</h5>
              <p>
                Create bioengineered solutions to combat climate change, reduce
                pollution, or promote sustainable practices in agriculture and
                industry.
              </p>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              className="mt-5"
            >
              <Tree size={100} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
