import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { ArrowRight } from "react-bootstrap-icons";
import EventsCards from "./EventsCards";
import im1 from "../Images/conference logo.png";

interface EventsProps {
  eventType: string;
}

export default function UpcomingForums(props: EventsProps) {
  const event1 = {
    title: "SynBIC Undergraduate Research Conference",
    date: "March 2024",
    location: "Imperial College London- South Kensington Campus",
    image: im1,
    description: `Calling all budding scientists, innovators, and researchers! 
    SynBIC, Imperial College London's premier student-led organization dedicated 
    to fostering the fields of synthetic biology, bioengineering, and STEM, is 
    thrilled to invite you to our eagerly anticipated Undergraduate Research Conference.  `,
    button: (
      <Button
        className="events-button-color"
        variant="outline-dark events-button"
        size="sm"
      >
        <span className="button-text">Sign Up</span> <ArrowRight />
      </Button>
    ),
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdM9IiqBhrjPRw-m7MoGXxiKxt_U5llDt2t2e22_duc8V3opQ/viewform",
  };

  return (
    <Container>
      <Stack
        className="events-line-spacing col-md-12 p-2"
        direction="horizontal"
        gap={3}
      >
        <div className="line"></div>
        <h2> {props.eventType}</h2>
        <div className="line"></div>
      </Stack>
      <Row>
        <Col>
          <EventsCards
            title={event1.title}
            date={event1.date}
            button={event1.button}
            location={event1.location}
            image={event1.image}
            description={event1.description}
            link={event1.link}
          />
        </Col>
      </Row>
    </Container>
  );
}
