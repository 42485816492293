export default function RegisterNow() {
  return (
    <div>
      <h2>🎟️ Register Now! 🎟️ </h2>
      <p>
        Don't miss out on this exceptional opportunity to be a part of the
        SynBIC Undergraduate Research Conference. Mark your calendars, prepare
        your abstracts, and get ready to be immersed in a world of scientific
        wonder.{" "}
      </p>

      <p>
        Join us in March as we celebrate the spirit of innovation, research,
        and collaboration in the world of science. Together, let's push the
        boundaries of possibility!
      </p>

      <p>See you at the conference! 👩‍🔬👨‍🔬</p>
    </div>
  );
}
