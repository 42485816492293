import Card from "react-bootstrap/Card";
import { CalendarCheck, GeoAltFill } from "react-bootstrap-icons";
import React, { ReactNode } from "react";

interface CardProps {
  title: string;
  date: string;
  location: string;
  image: any;
  description: string;
  button: ReactNode;
  link: string;
}

export default function EventsCards(props: CardProps) {
  return (
    
    <Card style={{ width: "18rem" }} className="mx-auto d-block">
      <Card.Img variant="top" src={props.image} className='card_title'/>
      <Card.Title>{props.title}</Card.Title>
      <Card.Subtitle className="events-card-spacing">
        <span className="span-card-spacing">
          <CalendarCheck className="events-logos" />
          {props.date}
        </span>
      </Card.Subtitle>
      <Card.Subtitle>
        <span className="span-card-spacing">
          <GeoAltFill className="events-logos" />
          {props.location}
        </span>
      </Card.Subtitle>
      <Card.Text className="events-text">{props.description}</Card.Text>
      <Card.Link href={props.link}>{props.button}</Card.Link>
    </Card>
  );
}
