import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

export default function NavBar() {
  return (
    <Navbar expand="lg" className="navbar-color" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={require("../Images/white_logo.png")}
            alt="synbic-logo"
            width="200"
            height="100"
            className="SynBic logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav variant="underline" defaultActiveKey="/home" className="ms-auto">
            <Nav.Link className="tab-color" href="/">
              Home
            </Nav.Link>
            <NavDropdown
              title={<span className='nav-events'>Events</span>}
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <NavDropdown.Item className="tab-color" href="/Hackathons">
                {" "}
                Hackathons
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="tab-color" href="/Conferences">
                {" "}
                Conferences
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="tab-color" href="/Competitions">
                {" "}
                Competitions
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="tab-color" href="/About">
              About
            </Nav.Link>
            <Nav.Link className="tab-color" href="/Contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
