import { useEffect } from "react";
import UpcomingForums from "../components/UpcomingForums";
import Container from "react-bootstrap/Container";
import WhyAttend from "../components/WhyAttend";
import Abstract from "../components/Abstract";
import WhoCanParticipate from "../components/WhoCanParticipate";
import RegisterNow from "../components/RegisterNow";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Forums() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div>
      <div className="conference-intro-spacing">
        <h1>SynBic Conferences</h1>
        <h2 className="mt-5">🚀 What to Expect? 🚀 </h2>
        <p className="events-desc-spacing">
          Get ready for two days of inspiration, discovery, and collaboration as
          we shine a spotlight on the remarkable accomplishments of
          undergraduate students in the field of science. Our conference is a
          unique platform for you to present your cutting-edge summer research
          to a captivated audience of fellow students, faculty, and industry
          professionals.{" "}
        </p>
      </div>

      <hr className="w-50 mx-auto contact-row-spacing" />

      <div className="conference-spacing">
        <WhyAttend />
      </div>

      <hr className="w-50 mx-auto contact-row-spacing" />

      <div className="conference-spacing" data-aos="fade-up">
        <Abstract />
      </div>

      <hr className="w-50 mx-auto contact-row-spacing" />
      <div className="conference-spacing" data-aos="fade-up">
        <WhoCanParticipate />
      </div>

      <hr className="w-50 mx-auto contact-row-spacing" />
      <div className="conference-spacing" data-aos="fade-up">
        <RegisterNow />
      </div>

      <Container>
        <div className='conference-bottom' data-aos="fade-up"> 
          <UpcomingForums eventType="Upcoming Conferences" />
        </div>
        {/* <PastEvents eventType="Past Forums" /> */}
      </Container>
    </div>
  );
}
