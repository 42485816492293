import React, { useEffect } from "react";
import OurTeam from "../components/OurTeam";
import TrackRecord from "../components/TrackRecord";
import Intro from "../components/Intro";
import JoinUs from "../components/JoinUs";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div>
      <div>
        <Intro />
      </div>

      <div data-aos="fade-up" className='home-spacing'>
        <JoinUs />
      </div>

      <hr className="w-50 mx-auto" />

      <div data-aos="fade-up" className='home-spacing'>
        <TrackRecord />
      </div>
      <hr className="w-50 mx-auto" />

      <div data-aos="fade-up" className="mx-auto justify-content-center home-spacing">
        <h2>Meet the committee:</h2>
        <OurTeam />
      </div>
    </div>
  );
};

export default Home;
