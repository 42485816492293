import React from "react";
import WhatIsSynBio from "../components/WhatIsSynBio";
import OurMission from "../components/OurMission";

const About = () => {
  return (
    <div className="about-spacing">
      <OurMission />
      <hr className="w-50 mx-auto contact-row-spacing" />
      <WhatIsSynBio />
    </div>
  );
};

export default About;
