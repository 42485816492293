import React from "react";
import WhatIsSynBio from "../components/WhatIsSynBio";
import OurMission from "../components/OurMission";
import IGEM from "../components/IGEM";
import BIOMOD from "../components/BIOMOD";

const Competitions = () => {
  return (
    <div>
      <div className="competitions-intro-spacing" >
        <h1>Synthetic Biology Competitions</h1>
        <p className="events-desc-spacing">
          Synthetic biology is a multidisciplinary field at the intersection of
          biology, engineering, and computer science, where teams of passionate
          synthetic biologists come together to design, build, and test various
          biological systems. To showcase their innovative creations, teams from
          around the world participate in synthetic biology competitions such as
          iGEM and BIOMOD to challenge their creativity, problem-solving skills,
          and scientific expertise.{" "}
        </p>
        <p className="events-desc-spacing">
          SynBIC is committed to nurturing the next generation of synthetic
          biologists by providing comprehensive training for competitions like
          iGEM and BIOMOD. Through workshops andmentorship programs, we aim to
          equip aspiring participants with the knowledge and skills necessary to
          excel in these prestigious competitions. Our goal is to empower these
          young minds to tackle real-world challenges and make a meaningful
          impact in the field of synthetic biology.{" "}
        </p>
      </div>
      <hr className="w-50 mx-auto contact-row-spacing" />

      <div className="conference-spacing">
        <IGEM />
      </div>

      <hr className="w-50 mx-auto contact-row-spacing" />

      <div className="conference-spacing">
        <BIOMOD />
      </div>
    </div>
  );
};

export default Competitions;
