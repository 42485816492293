import React from "react";
import Button from "react-bootstrap/Button";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../App.css";

export default function SocialMedia() {
  return (
    <div>
      <h1>Follow Us:</h1>
      <Container fluid>
        <Row>
          <Col xs={6} md={3}>
            <a href="https://www.instagram.com/synbic/">
              <Button
                variant="outline-dark"
                size="lg"
                className="contact-social-button"
              >
                <span className="button-text">
                  <Instagram /> Instagram
                </span>
              </Button>
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="https://www.linkedin.com/company/synbic/about/">
              <Button
                variant="outline-dark"
                size="lg"
                className="contact-social-button"
              >
                <span className="button-text">
                  {" "}
                  <Linkedin /> LinkedIn
                </span>
              </Button>
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="https://twitter.com/synbic">
              <Button
                variant="outline-dark"
                size="lg"
                className="contact-social-button"
              >
                <span className="button-text">
                  <Twitter /> Twitter
                </span>
              </Button>
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="https://www.facebook.com/SynBIC/">
              <Button
                variant="outline-dark"
                size="lg"
                className="contact-social-button"
              >
                <span className="button-text">
                  <Facebook /> Facebook
                </span>
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
