import { useEffect } from "react";
import Email from "../components/Email";
import SocialMedia from "../components/SocialMedia";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Location from "../components/Location";
import "../App.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Container className="top-row-spacing">
      <Row className="contact-row-spacing">
        <Col xs={12} md={6} className='contact-col-spacing'>
          <div id="contacts">
            <Email />
          </div>
        </Col>
        <Col xs={12} md={6} className='contact-col-spacing'>
          <Location />
        </Col>
      </Row>

      <Row className="w-50 mx-auto p-3 justify-content-center my-4 contact-row-spacing">
        <hr className="centered-hr" />
      </Row>

      <Row
        className="contact-row-spacing"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <Col xs={12} md={12}>
          <div id="socials">
            <SocialMedia />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
