import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { EnvelopeFill, GeoAltFill } from "react-bootstrap-icons";

// Actually contains the email, location and phone number but idk a better name for it
export default function Email() {
  const smallerText: React.CSSProperties = {
    fontSize: "14px",
  }; // TS instead of JS so need to do static typing

  return (
    <div>
      <h1 className="contact-align contact-us-spacing">Contact Us:</h1>

      <Container fluid>
        <Row className="align-items-center">
          <Col xs={1}>
            <Button
              className="email-button"
              variant="outline-dark"
              href="mailto:synbic@ic.ac.uk"
            >
              <EnvelopeFill className="bi bi-envelope-fill" />
            </Button>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
            <a href="mailto:synbic@ic.ac.uk" className="email-text">
              <h3 style={smallerText}>synbic@ic.ac.uk</h3>
            </a>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={1}>
            <Button
              className="email-button"
              variant="outline-dark"
              href="https://goo.gl/maps/B2ghuTzbgVswaC1v5"
            >
              <GeoAltFill className="bi bi-geo-alt-fill" />
            </Button>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
            <a
              href="https://goo.gl/maps/B2ghuTzbgVswaC1v5"
              className="email-text"
            >
              <h3 style={smallerText}>
                Exhibition Rd, South Kensington, London SW7 2BX
              </h3>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
