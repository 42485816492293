export default function Abstract() {
  return (
    <div>
      <h2>📝 Call for Abstracts 📝 </h2>
      <p>
        We invite you to submit your abstracts from September to December for a
        chance to present at our conference. Whether your research is in its
        early stages or nearing completion, we encourage all undergraduate
        students to share their insights and findings.{" "}
      </p>
    </div>
  );
}
