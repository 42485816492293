import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurMission() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Container fluid>
      <Row className="mission-spacing">
        <Col>
          <h1>Our mission</h1>
          <p>
            At Imperial’s Synthetic Biology Society, our mission is to cultivate
            a vibrant space for undergraduates to learn, collaborate, and
            innovate within the field of synthetic biology. We aim to:
          </p>
        </Col>
      </Row>

      <hr className="w-50 mx-auto contact-row-spacing" />

      <Row
        className="justify-content-center mb-5"
        data-aos="fade-right"
        data-aos-delay="500"
      >
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 4, order: 1 }}
          className="mt-5"
        >
          <h3>Organise Events</h3>
          <p>
            We organise workshops, seminars, and talks featuring experts in the
            field to provide members with insights into the latest advancements
            and applications of synthetic biology.
          </p>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 2 }}
          className="mt-5"
        >
          <img
            className="d-block mx-auto img-fluid"
            src={require("../Images/events.jpg")}
            width="300"
            height="300"
            alt="Mission 1"
          />
        </Col>
      </Row>

      <Row
        className="justify-content-center mb-5"
        data-aos="fade-left"
        data-aos-delay="650"
      >
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 1 }}
          className="mt-5"
        >
          <img
            className="d-block mx-auto img-fluid"
            src={require("../Images/competition.png")}
            width="300"
            height="300"
            alt="Mission 2"
          />
        </Col>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 4, order: 2 }}
          className="mt-5"
        >
          <h3>Organise Competitions</h3>
          <p>
            We offer opportunities for students from diverse disciplines can
            come together to apply their knowledge on hands-on projects to solve
            real-world problems. For this, we collaborate with organisations
            like Harvard OpenBio, Entrepreneurship First and MIT Hacking
            Medicine to organise hackathons, and we send teams on competitions
            like iGEM and BIOMOD.
          </p>
        </Col>
      </Row>

      <Row
        className="justify-content-center mb-5 align-items-center"
        data-aos="fade-right"
        data-aos-delay="650"
      >
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 4, order: 1 }}
          className="mt-5"
        >
          <h3>Facilitate Collaboration</h3>
          <p>
            We are part of the larger Imperial SynBio community comprising of
            organisations such as SynBICITE and London DNA Foundry. Given
            Imperial’s standing in Synthetic Biology, this helps us facilitate
            connections between like-minded students, faculty members, and
            professionals in the synthetic biology industry to enhance learning
            and career opportunities.{" "}
          </p>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 2 }}
          className="mt-5"
        >
          <img
            className="d-block mx-auto img-fluid"
            src={require("../Images/CSynB.jpg")}
            width="300"
            height="150"
            alt="Mission 3"
          />
        </Col>
      </Row>
    </Container>
  );
}
