import React from "react";

export default function Intro() {
  return (
    <header className='home-header'>
      <section >
      {/* <img
        src={require('../Images/home_overlay.jpg')} 
        alt="Header Background"
      /> */}
        <div className='text-overlay'>
        <h1>Welcome to Imperial's Student-Led Synthetic Biology Society! </h1>
        <p className="home-intro-spacing" style={{opacity: 1}}>
          Are you fascinated by the intersection of biology, engineering, and
          innovation? We are thrilled to introduce you to Imperial’s student-led
          Synthetic Biology Society, a dynamic and engaging community that
          brings together students who are enthusiastic about the exciting field
          of synthetic biology.{" "}
        </p>
        </div>
      </section>
    </header>
  );
}
