import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "aos/dist/aos.css";

export default function TrackRecord() {
  return (
    <Container fluid>
      <h1>What we have done:</h1>
      <Row className="mt-5 align-items-center">
        <Col xs={12} md={6} className='record-img-spacing'>
          <div data-aos="fade-right" data-aos-delay="1500">
            <img
              src={require("../Images/2016_iGEM.jpg")}
              alt="2016 iGEM team"
              width="300"
              height="300"
            />
            <h4 className="attend-spacing">iGEM 2016: Overall Winners</h4>
            <p className="attend-spacing ">
              <a
                href="https://2016.igem.org/Team:Imperial_College"
                className="intro-no-underline"
              >
                {" "}
                Eco-librium: developing a framework for engineering co-cultures{" "}
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div data-aos="fade-right" data-aos-delay="1000">
            <img
              src={require("../Images/2022_iGEM.png")}
              alt="2016 iGEM team"
              width="75%"
              height="75%"
            />
            <h4 className="attend-spacing">iGEM 2022: Gold Medal Winner</h4>
            <p className="attend-spacing ">
              <a
                href="https://2022.igem.wiki/imperial-college-london/"
                className="intro-no-underline"
              >
                {" "}
                Sporadicate: a broad-spectrum biofungicide based on Bacillus
                subtilis spores{" "}
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
