import { useEffect } from "react";
import UpcomingEvents from "../components/UpcomingEvents";
import Container from "react-bootstrap/Container";
import ChallengeAreas from "../components/ChallengeAreas";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Hackathons() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div >
      <header className='hackathon-header'>
      <section >
      <div className="text-overlay events-intro-spacing">
        <h1>SynBic Hackathons</h1>
        <p className="events-desc-spacing">
          Welcome to the "BioEngineering Innovations for Unmet
          Needs" hackathon, where innovation meets biology to address real-world
          challenges. This hackathon invites creative thinkers, engineers,
          biologists, and problem-solvers to come together and leverage the
          power of biological engineering to develop groundbreaking solutions
          for unmet needs across various domains. {" "}
        </p>
      </div>
      </section>
      </header>

      <Container className="hackathons-bottom-spacing">
        {/* <hr className="w-50 mx-auto contact-row-spacing" /> */}

        <div>
          <ChallengeAreas />
        </div>

        <div data-aos="fade-up">
          <UpcomingEvents eventType="Upcoming Hackathons" />
        </div>

        {/* <PastEvents eventType="Past Hackathons" /> */}
      </Container>
    </div>
  );
}
