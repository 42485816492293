import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { ArrowRight } from "react-bootstrap-icons";
import EventsCards from "./EventsCards";
import im1 from "../Images/logo.0030.png";

interface EventsProps {
  eventType: string;
}

export default function UpcomingEvents(props: EventsProps) {
  const event1 = {
    title: "BioEngineering Innovations for Unmet Needs",
    date: "TBC",
    location: "TBC",
    image: im1,
    description: `Welcome to the "BioEngineering Innovations for Unmet Needs" hackathon, where innovation meets biology to address real-world challenges. This hackathon invites creative thinkers, engineers, biologists, and problem-solvers to come together and leverage the power of biological engineering to develop groundbreaking solutions for unmet needs across various domains.`,
    button: (
      <Button
        className="events-button-color"
        variant="outline-dark events-button"
        size="sm"
        disabled
      >
        <span className="button-text">Sign Up</span> <ArrowRight />
      </Button>
    ),
    link: "#",
  };

  return (
    <Container>
      <Stack
        className="events-line-spacing col-md-12 p-2"
        direction="horizontal"
        gap={3}
      >
        <div className="line"></div>
        <h2> {props.eventType}</h2>
        <div className="line"></div>
      </Stack>
      <Row>
        <Col>
          <EventsCards
            title={event1.title}
            date={event1.date}
            button={event1.button}
            location={event1.location}
            image={event1.image}
            description={event1.description}
            link={event1.link}
          />
        </Col>
      </Row>
    </Container>
  );
}
