import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Aos from "aos";
import "aos/dist/aos.css";

export default function WhatIsSynBio() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Container
      className="justify-content-center"
      data-aos="fade-up"
      data-aos-delay="500"
    >
      <Row>
        <Col>
          <h1 className="contact-align">What is Synthetic Biology:</h1>
          <blockquote
            cite="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2174633/#:~:text=What%20is%20synthetic%20biology%3F,do%20not%20exist%20in%20nature."
            className="contact-align"
          >
            High-level Expert Group European Commission: “Synthetic biology is
            the engineering of biology: the synthesis of complex, biologically
            based (or inspired) systems which display functions that do not
            exist in nature.”
          </blockquote>
        </Col>
        <Col>
          <img
              src={require("../Images/About.jpg")}
              width='600'
              height='300'
              alt="About Syn Bio"
            />
        
        </Col>
      </Row>
    </Container>
  );
}
