export default function WhoCanParticipate() {
  return (
    <div>
      <h2>🎓 Who Can Participate? 🎓 </h2>
      <p>
        Undergraduate students from across the UK and beyond who are passionate
        about synthetic biology, bioengineering, and STEM are welcome to attend
        and submit their abstracts.
      </p>
    </div>
  );
}
