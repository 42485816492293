import React from "react";
import GoogleMapReact from "google-map-react";
import { GeoAltFill } from "react-bootstrap-icons";

interface MarkerProps {
  lat: number;
  lng: number;
} // Typescript props

const Marker: React.FC<MarkerProps> = (props) => {
    return <div className="location"><GeoAltFill/></div>;
};
export default function Location() {
  const defaultProps = {
    center: {
      lat: 51.49907122434536,
      lng: -0.17611888625492775,
    },
    zoom: 17,
  }; // save the location

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDqxaRc1HvLzQgEBDBog16LO8FJLtacLQ0" }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker lat={51.498861134995565} lng={-0.1747439137914471}/>
      </GoogleMapReact>
    </div>
  );
}
