import { Link } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function JoinUs() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div >
      <h2 className="join-us-subheading">Join Us!</h2>
      <h4 >
        Remember, at Imperial's Synthetic Biology Society, we're not just
        studying the future – we're shaping it.{" "}
      </h4>

      <div>
        <div
          className="join-us-spacing"
          data-aos="fade-up"
          data-aos-delay="550"
        >
          <h4 className="join-us-subheading">
            {" "}
            No matter your level of expertise, there's a place for you here to
            learn, grow, and contribute
          </h4>
          <p>
            Whether you're a biology enthusiast, an engineering prodigy, a
            computer science guru, or simply someone curious about the potential
            of synthetic biology, we welcome you to become a part of our
            community.{" "}
          </p>
        </div>

        <div
          className="join-us-spacing"
          data-aos="fade-up"
          data-aos-delay="750"
        >
          <h4 className="join-us-subheading">
            {" "}
            Stay updated on our upcoming events, projects, and activities!
          </h4>
          <p>
            Follow us on{" "}
            <Link to="/Contact#socials" className="join-us-links">
              our socials
            </Link>{" "}
            and join us at our upcoming events become a part of the exciting
            journey into the world of synthetic biology! For inquiries, reach
            out to us via{" "}
            <a href="/Contact#top" className="join-us-links">
              our contacts
            </a>
            , and let's embark on this fascinating adventure together.
          </p>
        </div>
      </div>
    </div>
  );
}
